import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';

import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';
import UnifiedExperience from '../images/unifiedExperience.svg';
import SalesforceGraph from '../images/salesforceGraph.png';




var lottie

export default class BetterEmail extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Phone System</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Email Tracking
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                Meet the most advanced email syncing product for Salesforce users
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="blueToGreen">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
                Clean & Complete Email Data 
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                Ensure all of your customer-facing email gets synced to the right Accounts, Contacts and Opportunities, even if contact data is missing.
              <br/><br/>
                Filter out all unwanted emails (marketing, calendar invites, etc) and deduplicate all group emails.
              <br/><br/>
                Extract all relevant metadata (ex: email signatures) and build custom metrics/automations on top of them (ex: auto-create contacts)

              </PageParagraph>
            </Box>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={SalesforceGraph} alt="Salesforce Dashboards" />
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={UnifiedExperience} alt="Get Your Company Communicating" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                Server Side Email Tracking
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                No matter what device, email client or automation tool you use, our system will capture every inbound and outbound email.
             

              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>


        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="darkLessTransparent">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <div
                ref={animationDiv => {
                  this.gaugeWebAnimation = animationDiv;
                }}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Accessible To All
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                Most sales tools make their pricing too prohibitive to extend email tracking to everyone.  This means customer facing emails from teams like finance, legal and customer success never make it into the CRM.  
                <br /> <br />
                That's why we offer the most affordable pricing for email tracking in the market. 
              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>

      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;